export const Categories = {
    Notice: {
        slug: "notices",
        title: "FCA Notices"
    },
    Warning: {
        slug: "warnings",
        title: "FCA Warnings"
    },
    FOI: {
        slug: "foi",
        title: "FOI"
    }
}